import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTargets.forEach((input) => {
      const answer = JSON.parse(input.dataset.userAnswer);
      if (answer != null) {
        let regex = /{{}}/g;
        const matchedInputs = input.innerHTML.match(regex);
        if (Boolean(matchedInputs)) {
          if (matchedInputs.length == 1) {
            const html = `<span class="parma"><strong>${answer.answer}</strong></span>`;
            input.innerHTML = `${input.innerHTML.replace(regex, html)}`;
          } else {
            answer.forEach((ans, i) => {
              regex = /{{}}/;
              const html = `<span class="parma"><strong>${ans.answer}</strong></span>`;;
              input.innerHTML = `${input.innerHTML.replace(regex, html)}`;
            });
          }
        }
      }
    })
  }


  updateScore() {
    fetchWithToken(event.currentTarget.dataset.path, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({score: event.currentTarget.value, quiz: event.currentTarget.dataset.quiz})
    })
      .then(response => response.json())
      .then((data) => {
        console.log(data);
      });
  }
}
