import { Controller } from "stimulus"
import { fetchWithToken } from "../packs/utils/fetch_with_token";

export default class extends Controller {
  static targets = ['list', 'input', 'vocListing', 'card']

  connect() {
  }

  create() {
    const listId = this.listTarget.dataset.listId;
    fetchWithToken('/vocs', {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        list_id: listId,
      })
    })
    .then(response => response.json())
    .then((data) => {
      this.vocListingTarget.insertAdjacentHTML('beforeend', data.html)
      document.getElementById(`list_vocs_attributes_${this.cardTargets.length - 1}_term`).focus();
    });
  }

  update(event) {
    const currentInput = event.currentTarget;
    const value = currentInput.value;
    const inputName = currentInput.name.split('[').pop().split(']').shift();
    const listId = this.listTarget.dataset.listId;
    const vocRank = currentInput.closest('.form-for-voc-card').parentElement.dataset.vocPos;
    let payload = {
      list_id: listId,
      rank: vocRank
    }
    payload[inputName] = value;
    fetchWithToken(`/vocs/${listId}`, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then((data) => {
      this.vocListingTarget.innerHTML = data.html;
      const inputs = this.inputTargets;
      const currentInputIndex = inputs.map(target => target.name).indexOf(currentInput.name);
      if (currentInputIndex + 1 < inputs.length) {
        inputs[currentInputIndex + 1].focus();
      }
    });
  }


  delete() {
    const vocRank = event.currentTarget.dataset.vocPos;
    const listId = this.listTarget.dataset.listId;
    fetchWithToken(`/vocs/${listId}`, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        list_id: listId,
        rank: vocRank
      })
    })
    .then(response => response.json())
    .then((data) => {
      this.vocListingTarget.innerHTML = data.html;
    });
  }
}
