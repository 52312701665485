import { Controller } from "stimulus";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/js/utils.js";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.initiatePhone();
  }

  validatePhone() {
    if (this.iti.isValidNumber()){
      this.inputTarget.value = this.iti.getNumber();
      if (this.inputTarget.parentElement.nextElementSibling) {
        this.inputTarget.parentElement.nextElementSibling.remove();
      }
    } else {
      if (!this.inputTarget.parentElement.nextElementSibling) {
        this.inputTarget.parentElement.insertAdjacentHTML('afterend', `<div class="input-error">Invalid mobile phone number</div>`)
      }
    }
  }

  initiatePhone() {
    this.iti = intlTelInput(this.inputTarget, {
      preferredCountries: ["fr", "us", "gb", "de", "es", "br"]
    });
    if (this.iti.isValidNumber()) {
      this.iti.setNumber(this.iti.getNumber());
    } else {
      this.inputTarget.value = "";
      this.iti.setCountry("fr");
    }
    this.validatePhone();
  }
}
