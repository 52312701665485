import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import tippy from 'tippy.js';


export default class extends Controller {
  static targets = ['tooltip', 'container', 'richDescription', 'teacher', 'stat', 'shared']

  connect() {
    this.fetchSessionTooltips();

    this.teacherTargets.forEach((teacher) => {
      if (teacher) {
        const tip = tippy(teacher, {
          content: `${teacher.dataset.name}`,
          allowHTML: true,
          // interactive: true,
          placement: 'auto'
        })
      }
    })

    this.statTargets.forEach((teacher) => {
      if (teacher) {
        const tip = tippy(teacher, {
          allowHTML: true,
          // interactive: true,
          placement: 'auto'
        })
      }
    })
  }

fetchSessionTooltips() {
    const computeContent = (tooltip) => {
      fetchWithToken(tooltip.dataset.tooltip, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: parseInt(tooltip.dataset.sessionId, 10),
          position: parseInt(tooltip.dataset.position, 10),
          from: tooltip.dataset.from
        })
      })
      .then(response => response.json())
      .then((data) => {
        // console.log(tooltip.parentElement.parentElement.parentElement.parentElement);
        const tip = tippy(tooltip, {
          trigger: 'click',
          content: `${data.html}`,
          allowHTML: true,
          interactive: true,
          placement: 'auto',
          maxWidth: 300,
          popperOptions: {
            modifiers: [
              {
                name: 'flip',
                options: {
                  boundary: tooltip.parentElement.parentElement.parentElement.parentElement,
                  padding: 16,
                },
              }
            ],
          },
        })
        tip.popper.querySelector('.tippy-box').classList.add('custom-scrollbar');
        tip.popper.querySelector('.tippy-box').classList.add('custom-corn-yellow-scrollbar');
        const sessionDescription = tip.popper.querySelector('.session-description .trix-content');
        if (sessionDescription){
          const resources = sessionDescription.querySelector('ul:not(:first-of-type)');
          if (resources) {resources.remove()};
          const extradivs = sessionDescription.querySelectorAll('div:not(:first-of-type)');
          extradivs.forEach(extradiv => extradiv.remove());
        }
        const sharedTip = tip.popper.querySelector("[data-tooltip-target]");
        if (sharedTip){
          tippy(sharedTip, {
            allowHTML: true,
          })
        };

      });
    }
    this.tooltipTargets.forEach((tooltip) => {
      if (tooltip) {
        computeContent(tooltip);
      };
    });
  }

}
