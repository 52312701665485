import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {

  }

  toggleSideNav() {
  	event.currentTarget.classList.toggle('toggled');
  	this.containerTarget.classList.toggle('expanded');
  }
}
