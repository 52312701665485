import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['carousel', 'carouselItem' ]

  connect() {
  }

  navigate() {
    const carousel = this.carouselTarget;
    const carouselPosition = parseFloat(carousel.dataset.translate);
    const eventItem = event.currentTarget.parentElement.parentElement;
    const eventItemPosition = parseFloat(eventItem.style.width.split('%')[0]);
    if (event.currentTarget.classList.contains('fa-chevron-right')) {
      carousel.style.transform = `translateX(${carouselPosition - eventItemPosition}%)`;
      carousel.dataset.translate = `${carouselPosition - eventItemPosition}`;
    }
     else {
      carousel.style.transform = `translateX(${carouselPosition + eventItemPosition}%)`;
      carousel.dataset.translate = `${carouselPosition + eventItemPosition}`;
    }
  }
}
