import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['voc', 'vocContainer', 'progressbar'];

  connect() {
    this.computeScore();
    this.vocTargets.forEach((voc) => {
      if (JSON.parse(voc.dataset.word).definition === "") {
        voc.style.cursor = 'text';
      }
    });
  }

  computeScore() {
    if (this.hasProgressbarTarget) {
      const score = Math.round(this.progressbarTarget.dataset.score);
      const bar = this.progressbarTarget.querySelector('#progress').style.strokeDashoffset = (100 - score);
    }
  }

  flipCard(event) {
    if (!event.target.classList.contains("listen")) {
      const word = JSON.parse(event.currentTarget.dataset.word);
      if (word.definition !== "") {
        this.flipVoc(event.currentTarget);
      }

    }
  }

  flipAll(){
    this.vocTargets.forEach(voc => this.flipVoc(voc));
  }

  flipVoc(card) {
    card.style.transition = "transform 0.5s";
    setTimeout(function(){
      const front = card.querySelector(".voc-card-front");
      const listen = card.parentNode.querySelector(".listen");
      card.querySelector(".voc-card-back").classList.toggle('invisible');
      front.classList.toggle('invisible');
      listen.classList.toggle('hidden');
    }, 200)
    card.classList.toggle('flipped');
  }

  shuffle() {
    fetch(event.currentTarget.dataset.path, { headers: { accept: 'application/json' } })
      .then(response => response.json())
      .then((data) => {
        this.vocContainerTarget.innerHTML = data.html;
      });
  }

}
