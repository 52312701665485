import { Controller } from "stimulus";
import Swal from 'sweetalert2';
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = [];

  connect() {

  }


  delete() {
    const batch_id = event.currentTarget.dataset.batch;
    const session_id = event.currentTarget.dataset.session;
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir détruire cette session ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00E4A3',
      cancelButtonColor: '#504B73',
      confirmButtonText: '👍',
      cancelButtonText: '😱😅'
    }).then((result) => {
      if (result.value) {
      	let path = this.element.dataset.path
      	path = (path != undefined) ? path : `/batches/${batch_id}/sessions/${session_id}`
        fetch(path, {
          headers: { accept: "application/json", "X-CSRF-Token": csrfToken(), credentials: "same-origin" },
          method: "DELETE",
          body: JSON.stringify({id: session_id})
        })
          .then(response => response.json())
          .then((data) => {
            if (data.success) {
              window.location.reload();
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                icon: 'success',
                title: '👏 La session a été détruite !',
                timer: 1500
              })
            }
          });
      }
    })
  }

}
