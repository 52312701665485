import { Controller } from "stimulus";
import Swal from 'sweetalert2';

export default class extends Controller {
  // static targets = ["input"];

  connect() {

  }

  alert() {
    let text;
    if (event.type == "blur") { text = '👋 Happy to see you back!'}
    Swal.fire({
      // icon: 'info',
      title: `${text}`,
      text: "Remember: try to not use any extra help during your assessment test.",
      imageUrl: "https://media.giphy.com/media/dk3TmFZpn6pUI/giphy.gif",
      imageWidth: 400,
      imageHeight: 200,
      confirmButtonColor: '#504B73',
      confirmButtonText: '👍😅'
    })
  }
}
