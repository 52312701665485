import { Controller } from "stimulus";
import ProgressBar from "progressbar.js";

export default class extends Controller {
  static targets = ["progress"];

  connect() {
    this.element[this.identifier] = this;
    let countdown;
    clearInterval(countdown);
    const now = Date.now();
    const seconds = this.progressTarget.dataset.max;
    const then = now + seconds * 1000;
    this.initializeBar();
    this.displayTimeLeft(seconds);
    this.countdown = setInterval(() => {
      const secondsLeft = Math.round((then - Date.now()) / 1000);
      if (secondsLeft < 0) {
        clearInterval(countdown);
        const submitBtn = document.getElementById("submit-exam-submission-form");
        if (submitBtn) {
          submitBtn.disabled = false;
        }
        return;
      }
      this.progressTarget.dataset.value = secondsLeft;
      this.bar.set(secondsLeft / seconds);
      this.displayTimeLeft(secondsLeft);
    }, 1000);


  }

  initializeBar() {
    const progressBar = this.progressTarget;
    const colorFirst = progressBar.dataset.colorFirst;
    const colorLast = progressBar.dataset.colorLast;

    let linearGradientBar = `
      <defs>
        <linearGradient id="gradientBar" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stop-color="${colorFirst}"/>
          <stop offset="100%" stop-color="${colorLast}"/>
        </linearGradient>
      </defs>
    `;

    this.bar = new ProgressBar.Line(progressBar, {
      strokeWidth: 5,
      color: 'url(#gradientBar)',
      trailColor: '#E5E5E5',
      trailWidth: 5,
      easing: 'easeInOut',
      svgStyle: {
        display: 'block',
        width: '100%',
        borderRadius: '4px',
      },
      text: {
        value: '',
        alignToBottom: false,
        style: {
          color: '#504B73',
          position: 'absolute',
          fontWeight: '700',
          fontSize: 'small',
          right: '0',
          top: '-72%',
          padding: 0,
          margin: 0,
          // You can specify styles which will be browser prefixed
          transform: {
            prefix: true,
            value: 'translate(-50%, 50%)'
          }
        },
      }
    });
    this.bar.svg.insertAdjacentHTML('afterBegin', linearGradientBar);
    this.bar.set(1);
  }

  displayTimeLeft(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainderSeconds = seconds % 60;
    const display = `${minutes}:${remainderSeconds < 10 ? '0' : ''}${remainderSeconds}`;
    document.title = `🔥 Exam Quiz - ${display}`;
    if (this.bar) {
      this.bar.setText(display);
    }
  }

  disconnect() {
    clearInterval(this.countdown);
  }
}
