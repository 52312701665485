import { Controller } from "stimulus";
import Plyr from "plyr";

export default class extends Controller {
  static targets = ["header", "section", "sectionContainer", "modal", "modalContent", "player", "iFrame", "loading"];

  connect() {
    document.addEventListener('ready', () => {
      this.sectionsTop = this.sectionContainerTargets.map(section => JSON.parse(this.rebuild(section)));
      this.element.parentNode.addEventListener('scroll', (e) => {
        this.activeHeader(event);
        this.activeSection(event);
      })
    });
  }

  computeScrollOnResize() {
    this.sectionsTop = this.sectionContainerTargets.map(section => JSON.parse(this.rebuild(section)));
  }

  rebuild(section) {
    return JSON.stringify({
      id: section.id,
      dim: [section.getBoundingClientRect().top - this.headerTarget.getBoundingClientRect().height, section.getBoundingClientRect().bottom - this.headerTarget.getBoundingClientRect().height]
    })
  }

  activeHeader(event) {
    if (event.currentTarget.scrollTop >=  this.headerTarget.getBoundingClientRect().top - 30) {
      this.headerTarget.classList.add('active');
    } else {
      this.headerTarget.classList.remove('active');
    }
  }

  activeSection(event) {
    let scrollY = event.currentTarget.scrollTop ;
    if (scrollY + 30 < this.headerTarget.getBoundingClientRect().height) {
      this.sectionTargets.forEach(tab => tab.classList.remove('active'));
    } else {
      scrollY = scrollY + this.headerTarget.getBoundingClientRect().height + 30;
    }
    this.sectionsTop.forEach((section) => {
      if (scrollY > section.dim[0] && scrollY < section.dim[1]){
        const highlight = this.sectionTargets.filter(tab => tab.dataset.section === section.id)[0];
        if (!highlight.classList.contains('active')) {
          this.sectionTargets.forEach(tab => tab.classList.remove('active'));
          highlight.classList.add('active');
        }
      }
    });
  }

  scrollToSection() {
    this.sectionTargets.forEach(section => section.classList.remove('active'));
    event.currentTarget.classList.toggle('active');
    if (event.currentTarget.classList.contains('active')) {
      const target = event.currentTarget.dataset.section;
      const targetElement = document.getElementById(target);
      const scrollY = this.sectionsTop.filter(section => section.id === target)[0].dim[0] - this.headerTarget.getBoundingClientRect().height - 30;
      this.element.parentNode.scrollTo({
        left: 0,
        top: scrollY + 20,
        behavior: 'smooth'
      });
    }
  }


  watchMovie() {
    const watchModal = this.modalTarget;
    const modalContent = this.modalContentTarget;
    const iFrame = this.iFrameTarget;
    const dataVideo = event.currentTarget.dataset.video;
    $('#watch-trailer').on('shown.bs.modal', (e) => {
      const h = modalContent.offsetHeight;
      const w = modalContent.offsetWidth;
      iFrame.setAttribute('src', `https://www.youtube.com/embed/${dataVideo}?autoplay=1modestbranding&iv_load_policy=3&showinfo=0&cc_load_policy=3&playsinline=1&rel=0&enablejsapi=1&origin=${window.location.origin}`);
      const moviePlayer = new Plyr(this.playerTarget, {
        speed: { selected: 1, options: [0.5, 0.75, 1]},
      });
      moviePlayer.on('ready', (e) => {
        this.loadingTarget.style.display = "none";
        moviePlayer.play();
      });
    });
    $('#watch-trailer').on('hidden.bs.modal', (e) => {
      this.loadingTarget.style.display = "block";
      this.playerTarget.innerHTML = `
        <iframe src="" frameborder="0" allow="autoplay; fullscreen; transparency" data-papl-target="iFrame"></iframe>
      `
    });
  }

}
