import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["loading", "iframe"];

  connect() {
    this.iframeTarget.onload = () => {
      this.loadingTarget.style.display = "none";
    }
  }
}
