import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["navigationDropdown", "navbarDropdownLauncher", "batchSelector", "batchDropdown"];

  connect() {
  }

  toggleActive() {
    const origin = event.target;
    /// MAIN-MENU-NAVBAR-DROPDOWN
    if (this.hasNavbarDropdownLauncherTarget && this.navbarDropdownLauncherTarget.contains(origin)) {
      if (!this.navigationDropdownTarget.contains(origin)){
        const targetedElement = this.navigationDropdownTarget;
        targetedElement.classList.toggle('active');
        this.navbarDropdownLauncherTarget.classList.toggle('active');
      }
    } else if ((this.hasNavigationDropdownTarget && !this.navigationDropdownTarget.contains(origin))) {
      this.navigationDropdownTarget.classList.remove('active');
      this.navbarDropdownLauncherTarget.classList.remove('active');
    }
  }

  batchSelect() {
    const origin = event.target;
    if (this.hasBatchDropdownTarget) {
      if (this.batchSelectorTarget.contains(origin)){
        if (!this.batchDropdownTarget.contains(origin)) {
          const targetedElement = this.batchDropdownTarget;
          targetedElement.classList.toggle('active');
          this.batchSelectorTarget.classList.toggle('active');
        }
      } else if ((!this.batchDropdownTarget.contains(origin))) {
        this.batchSelectorTarget.classList.remove('active');
        this.batchDropdownTarget.classList.remove('active');
      }
    }
  }
}
