import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import tippy from 'tippy.js';

export default class extends Controller {

  connect() {
  	const data = this.computeHtml();
  	if (data) {
	  	tippy(this.element, {
	  	  trigger: 'click',
	  	  content: `${data}`,
	  	  allowHTML: true,
	  	  interactive: true,
	  	  theme: 'student',
	  	})

  	}
  }

  computeHtml() {
  	if (this.element.dataset.students) {
	  	const students = JSON.parse(this.element.dataset.students);
	  	let path
	  	if (this.element.classList.contains('admin')) {
	  		path = this.element.dataset.path;
	  	}
	  	if (students.length != 0) {
		  	return `
		  	<ul>
		  		${students.map(st => this.computeStudentHTML(st, path)).join('')}
		  	</ul>
		  	`;
	  	}
  	}
  }

  computeStudentHTML(student, path) {
  	if (path) {
  		return `<li>${student.first_name} ${student.last_name}. ${student.nationality ? student.nationality : ""} | <i class="far fa-trash-alt" data-action="click->fetch-session#removeAttendee" data-path="${path}?batch_person_id=${student.id}"></i></li>`
  	} else {
  		return `<li>${student.first_name} ${student.last_name}. ${student.nationality ? student.nationality : ""}</li>`
  	}
  }
}

