import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["activable"];

  activate() {
  	this.activableTargets.forEach((activable) => {
  		const fetch = document.getElementById(activable.dataset.fetchId);
  		[fetch, activable].forEach(el => {if (el) { el.classList.remove('active') }});
  		if (activable == event.currentTarget && !activable.classList.contains('active')) {
  			[activable, fetch].forEach(el => {if (el) { el.classList.add('active') }});
  		}
  	})
  }

}
