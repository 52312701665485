import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import Plyr from 'plyr';
import { annotate } from 'rough-notation';

export default class extends Controller {
  static targets = ["player", "banner", "consigne", "toQuestions", "container", "contentContainer", "question", "input", "next", "video", "modal", "highlight", "loading"]

  connect() {
    if (this.hasBannerTarget) {this.loadPlayer()};
    this.highlightTargets.forEach(element => this.highlight(element));
  }

  loadPlayer() {
    const player = new Plyr(`#${this.playerTarget.id}`, {
      speed: {
        selected: 1,
        options: [.5, .75, 1]
      }
    });
    player.on('ready', () => {this.loadingTarget.style.display = "none";})

    player.on('pause', () => {
      this.bannerTarget.classList.toggle('invisible');
      this.consigneTarget.classList.toggle('hidden');
    })

    player.on('play', () => {
      if (!this.bannerTarget.classList.contains('invisible')){
        this.bannerTarget.classList.toggle('invisible');
        this.consigneTarget.classList.toggle('hidden');
      }
    })
  }

  toQuestions() {
    this.element.scroll(0,0);
    this.bannerTarget.remove();
    const url = `${event.currentTarget.dataset.path}?step=${event.currentTarget.dataset.step}`;
    fetch(url, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
    .then((data) => {
      this.containerTarget.innerHTML = data.html;
    });
  }

  nextQuestion() {
    const chosenOptions = this.inputTargets.filter(input => input.checked).flatMap(input => parseInt(input.value, 10));
    const questionId = this.questionTarget.id;
    fetchWithToken(event.currentTarget.dataset.path, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        question_id: questionId,
        chosen_options: chosenOptions
      })
    })
    .then(response => response.json())
    .then((data) => {
      if (data.finished) {
        this.containerTarget.innerHTML = data.html;
        this.highlightTargets.forEach(element => this.highlight(element));
      } else {
        this.contentContainerTarget.innerHTML = data.html;
      }
      this.element.scrollIntoView();
    });
  }

  toggleSelect() {
    switch (this.questionTarget.dataset.type) {
      case 'boolean' :
        this.toggleBooleanQuestion();
        break;
      case 'qcm' :
        this.toggleQcmQuestion();
        break;
      case 'multiselect' :
        this.toggleMultiQuestion();
        break;
    }
  }

  toggleMultiQuestion() {
    const inputs = this.inputTargets;
    const checkedInputs = inputs.filter(input => input.checked);
    const totalOption = parseInt(event.currentTarget.parentNode.parentNode.dataset.options);
    if (checkedInputs.length <= totalOption) {
      event.currentTarget.parentNode.classList.toggle('selected');
      this.mark(event.currentTarget.parentNode, 'box');
      this.nextTarget.style.transform = "translateY(100%)";
      this.videoTarget.style.transform = "translateY(0%)";
    } else {
      event.currentTarget.checked = false;
      event.currentTarget.parentNode.classList.remove('selected');
    }
    if (checkedInputs.length === totalOption) {
      this.toggleNext();
    }
  }

  toggleBooleanQuestion() {
    const inputs = this.inputTargets;
    const checkedInputs = inputs.filter(input => input.checked)
    if (checkedInputs.length === inputs.length) {
      inputs.splice(inputs.indexOf(event.currentTarget), 1);
      inputs.forEach((input) => {
        input.checked = false;
        input.parentNode.classList.remove('selected');
        this.draw(input.parentNode, 'circle', 'remove');
      })
      event.currentTarget.parentNode.classList.toggle('selected');
      this.mark(event.currentTarget.parentNode, 'circle');
    }
    else {
      event.currentTarget.parentNode.classList.toggle('selected');
      this.mark(event.currentTarget.parentNode, 'circle');
    }
    this.toggleNext();
  }

  toggleQcmQuestion(){
    const inputs = this.inputTargets;
    const checkedInputs = inputs.filter(input => input.checked)
    if (checkedInputs.length < 1) {
      event.currentTarget.parentNode.classList.toggle('selected');
      this.mark(event.currentTarget.parentNode, 'box');
    } else {
      checkedInputs.splice(checkedInputs.indexOf(event.currentTarget), 1);
      checkedInputs.forEach((input) => {
        input.checked = false;
        input.parentNode.classList.remove('selected');
        this.draw(input.parentNode, 'box', 'remove');
      })
      event.currentTarget.parentNode.classList.toggle('selected');
      this.mark(event.currentTarget.parentNode, 'box');
    }
    this.toggleNext();
  }

  toggleNext() {
    if (this.inputTargets.some((input) => input.checked)) {
      this.nextTarget.style.transform = "translateY(0%)";
      this.videoTarget.style.transform = "translateY(100%)"
    } else {
      this.nextTarget.style.transform = "translateY(100%)";
      this.videoTarget.style.transform = "translateY(0%)";
    }
  }


  watch() {
    const event = document.createEvent("CustomEvent");
    event.initCustomEvent("watch-video", true, true, null);
    this.element.dispatchEvent(event);
  }


  highlight(element) {
    const annotation = annotate(element, {
      type: 'highlight',
      color: '#FEF68E'
    });
    annotation.show();
  }

  mark(element, type) {
    if (element.classList.contains('selected')) {
      this.draw(element, type, '');
    } else {
      this.draw(element, type, 'remove');
    };
  }

  draw(element, type, action) {
   if (action === "remove") {
    element.nextElementSibling.remove();
   } else {
     const annotation = annotate(element, {
       type: type,
       color: '#E62C76',
       strokeWidth: 2
     });
     annotation.show();
   }
  }
}
