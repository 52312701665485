import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['frame']

  connect() {
  }

  zoomIn() {
    const target = document.getElementById(event.currentTarget.dataset.target);
    target.classList.toggle('hidden');
    target.innerHTML = event.currentTarget.innerHTML;
  }

  zoomOut() {
    event.currentTarget.classList.toggle('hidden');
    event.currentTarget.innerHTML = "";
  }
}
