import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['fillIt', 'answers', 'mainSection', 'listItem']

  connect() {
    if (this.hasFillItTarget) {
      this.fillItTargets.forEach((declaration) => {
        const inner = declaration.dataset.inner;
        const html = `<input type="text"></input>`
        declaration.innerHTML = inner.replace(/{{}}/g, html).replace(/\\n/g, "<br/>");
        // this.fragmentize(declaration);
        const answers = declaration.dataset.answers;
        if ( answers != "") { this.buildEmptyFill(declaration, answers) }
      })
    }

    if (this.hasListItemTarget) {
      this.listItemTargets.forEach((declaration) => {
        const html = `<input type="text"></input>`
        declaration.innerHTML = declaration.innerHTML.replace(/{{}}/g, html);
      })
    }
  }

  fragmentize(element) {
    const listItems = Array.from(element.querySelectorAll('li'));
    listItems.shift();
    listItems.forEach((li) => {
      li.classList.add('fragment');
      li.classList.add('fade-up');
    });
  }

  buildEmptyFill(declaration, answers){
    const questions = declaration.querySelectorAll("li");
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = answers;
    const realAnswers = Array.from(tempDiv.querySelectorAll("li"));
    let emptyFill = [];
    questions.forEach((question, index) => {
      emptyFill.push({question: question.innerHTML, answer: realAnswers[index].innerHTML});
    })
    emptyFill.reverse().forEach(emptyFillItem => {
      const html = this.buildEmptyFillItem(emptyFillItem, declaration);
      Array.from(this.mainSectionTargets)[declaration.dataset.index].insertAdjacentHTML('afterend', html)
    })
  }

  buildEmptyFillItem(emptyFillItem, declaration) {
    const section = Array.from(this.mainSectionTargets)[declaration.dataset.index]
    const html = `
      <section class="section-${section.dataset.datype}">
        <div class="slide-content">
          <div class="header">${section.dataset.locale} | © novexpat</b></div>
            <div class="actico-show">
              <p class="question-title">→ ${emptyFillItem.question}</p>
              <div class="fragment fade-up answer box-shadowed">
                <span>${section.dataset.answerLocale}</span>
                👉 ${emptyFillItem.answer}
              </div>
            </div>
        </div>
      </section>
    `
    return html
  }
}
