import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "timeElapsed", "inputable", "submit", "oralInput", "stopOralButton", "assessInput", "assessWrapper", "assessContainer"];

  connect() {
    this.inputTargets.forEach((input) => {
      const quizId = input.dataset.quiz;
      const questionId = input.dataset.question;
      const questionType = input.dataset.questionType;
      const html = `<input className="form-control string" type="text" name="exam_submission[${quizId}][answers][${questionId}]" id="exam_submission_${quizId}_answers_${questionId}" data-action="keyup->exam#formIsSubmitable" data-exam-target="inputable" data-question-type="${questionType}">`
      input.innerHTML = input.innerHTML.replace(/{{}}/g, html);
      this.makeIdUnique(quizId, questionId);
    });
    if (this.hasSubmitTarget || this.hasStopOralButtonTarget) {
      this.initilizeTimer();
      this.formIsSubmitable();
    }
  }

  makeIdUnique(quizId, questionId) {
    const inputs = document.querySelectorAll(`#exam_submission_${quizId}_answers_${questionId}`);
    if (inputs.length > 1) {
      inputs.forEach((input, index) => {
        input.setAttribute("name", `exam_submission[${quizId}][answers][${questionId}_${index}]`);
        input.setAttribute("id", `#exam_submission_${quizId}_answers_${questionId}_${index}`);
      })
    }
  }

  initilizeTimer() {
    this.startTime = new Date();
  }

  formIsSubmitable() {
    if (this.inputableTargets.length != 0) {
      let notAnswered = this.inputableTargets.filter(input => input.value == '');
      let questionType = Array.from(new Set(this.inputableTargets.map(input => input.dataset.questionType)))[0];
      if (notAnswered.length == 0 || (questionType === "place_and_fill" && this.#allInputsAreAnswered()) ) {
        this.submitTarget.disabled = false;
      } else {
        this.submitTarget.disabled = true;
      }
    }
    if (this.oralInputTargets.length != 0) {
      const checkedInput = this.oralInputTargets.filter(input => input.checked);
      this.submitTarget.disabled = checkedInput.length == 0;
    }
    if (this.assessInputTargets.length != 0) {
      const checkedInput = this.assessInputTargets.filter(input => input.checked);
      this.submitTarget.disabled = checkedInput.length !== this.assessWrapperTargets.length;
    }
  }

  #allInputsAreAnswered() {
    return this.inputTargets.map(input => this.#inputIsAnswered(input)).every(answer => answer === true);
  }

  #inputIsAnswered(input) {
    return Array.from(input.querySelectorAll("[data-exam-target='inputable']")).filter(inputable => inputable.value != '').length > 0;
  }

  sendForm() {
    event.preventDefault();
    this.setTimeElapsed();
    this.element.submit();
  }

  setTimeElapsed() {
    event.preventDefault();
    if (this.timeElapsedTarget.value == 0) {
      const endTime = new Date();
      let elapsedTime = endTime.getTime() - this.startTime.getTime()
      elapsedTime = elapsedTime / 1000
      this.timeElapsedTarget.value = Math.floor(elapsedTime);
    }
    if (this.hasStopOralButtonTarget) {
      this.stopOralButtonTarget.parentElement.remove();
      this.submitTarget.classList.remove("hidden");
      this.assessContainerTarget.classList.remove("hidden");
    }
  }

  makeAvailable(){
    this.stopOralButtonTarget.disabled = false;
    const progress = document.querySelector("[data-controller='timer']");
    if (progress) { progress.dataset.controller = "" }
  }
}
