import { Controller } from "stimulus";
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['progressbar', 'participantTooltip'];

  connect() {
    this.computeScore();
    this.initializeParticipantsTooltips();
  }

  initializeParticipantsTooltips(){
    this.participantTooltipTargets.forEach((tooltip) => {
      const participants = JSON.parse(tooltip.dataset.tooltipContent);
      const tip = tippy(tooltip, {
        content: `
          <ul class="participants-tooltip">
            ${this.buildListItem(participants)}
          </ul>`,
        allowHTML: true,
        placement: 'auto-end'
      })
    })
  }

  buildListItem(array){
    return array.map(element => `<li>${element}</li>`).join('')
  }

  computeScore() {
    this.progressbarTargets.forEach((progressbar) => {
      const hours = parseFloat(progressbar.dataset.hours);
      const classPackage = parseFloat(progressbar.dataset.package);
      let progress = Math.round(hours/classPackage * 100);
      progress = (progress >= 100) ? 100 : progress;
      progressbar.classList.add(this.computeAppreciate(progress));
      const bar = progressbar.querySelector('#progress').style.strokeDashoffset = (100 - progress);
    });
  }

  computeAppreciate(progress) {
    if (progress == 100) {return "finished"}
    else if (progress >= 70 && progress != 100){return "third-tier"}
    else if (progress >= 30 && progress < 70){return "second-tier"}
    else {return "first-tier"}
  }
}
