import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ["session", "container", "toggler"];

  fetch() {
  	const session = event.currentTarget;
  	this.sessionTargets.forEach(session => session.classList.remove('active'));
  	fetchWithToken(session.dataset.path, {
  	  method: "GET",
  	  headers: {
  	    "Accept": "application/json"
  	  }
  	})
  	.then(response => response.json())
  	.then((data) => {
  		this.containerTarget.innerHTML = data.html;
  	  session.classList.add('active');
      if (this.hasTogglerTarget) {
        this.togglerTarget.click();
      }
  	});
  }

  fetchUserSessions() {
  	const tooltipable = event.currentTarget;
  	const path = tooltipable.dataset.path;
  	fetchWithToken(path, {
  	  method: "GET",
  	  headers: {
  	    "Accept": "application/json"
  	  }
  	})
  	.then(response => response.json())
  	.then((data) => {
  		const tip = tippy(tooltipable, {
  		  trigger: 'click',
  		  content: `${data.html}`,
  		  allowHTML: true,
  		  interactive: true,
  		  theme: 'student'
  		})
  		tip.show();
  	});
  }

  removeAttendee() {
  	const path = event.currentTarget.dataset.path;
  	fetchWithToken(path, {
  	  method: "GET",
  	  headers: {
  	    "Accept": "application/json"
  	  }
  	})
  	.then(response => response.json())
  	.then((data) => {
  		this.containerTarget.innerHTML = data.html;
  	});
  }

}
