import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";

export default class extends Controller {
  static targets = ["token"]

  connect() {

  }

  checkToken() {
  	event.preventDefault();
  	const path = event.currentTarget.dataset.path;
  	fetchWithToken(path, {
      method: 'POST',
      redirect: 'follow',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: this.tokenTarget.value
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.ok) {
      	this.element.innerHTML = data.html;
        // window.location.href = data.url;
      } else {
        const swalLinkAlert = document.querySelectorAll('.alert_batch_not_valid');
        if (swalLinkAlert) { // protect other pages
          swalLinkAlert.forEach((link) => {
            Swal({
              title: "This token doesn't exist!",
              html: "Please, contact your class teacher.",
              type: "error",
              confirmButtonText: "OK"
            })
          })
        }
      }
    })
  }
}
