import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sidebar", "open", "closeIcon"];

  connect() {
  }

  toggleSideNav() {
    const sidebar = this.sidebarTarget;
    this.openTarget.classList.toggle("toggled");
    event.currentTarget.querySelector('.icon-close').classList.toggle('toggled');
    sidebar.classList.toggle("invisible");
    if (!sidebar.classList.contains('invisible')) {
      const activeElement = sidebar.querySelector('.active');
      if (activeElement) {
        const activeOffset = activeElement.offsetTop - 110;
        sidebar.scrollTo(0, activeOffset);
      }
    }
  }

  disconnect() {
    if (this.hasSidebarTarget) {
      const sidebar = this.sidebarTarget;
      if (!sidebar.classList.contains('invisible')) {
        this.closeIconTarget.classList.toggle('toggled');
        sidebar.classList.toggle("invisible");
      }
    }
  }
}
