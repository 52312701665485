import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ["contactInput", "contactForm", "submit"];

  connect() {
    this.contactInputTargets.forEach(input => input.classList.remove('is-valid'));
  }

  validateContactInput() {
    const inputs = this.contactInputTargets;

    let hash = {}
    inputs.forEach((input) => {
      this.buildSentData(hash, input)
    });
    this.fetchValidateContact(hash, this.contactFormTarget.dataset.path);
  }

  validateForm(){
    if (document.querySelectorAll('.input-error').length === 0) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    };
  }


  buildSentData(hash, input) {
    const inputName = input.id.match(/user_(.*)/)[1];
    if (input.type == "checkbox"){
      hash[`${inputName}`] = input.checked;
    } else {
      hash[`${inputName}`] = input.value;
    }
  }

  fetchValidateContact(hash, path) {
    fetchWithToken(path, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: hash
      })
    })
    .then(response => response.json())
    .then((data) => {
      if (data.errors) {
        this.toggleErrors(data)
      } else {
        const inputErrors = Array.from(document.querySelectorAll('.input-error'));
        inputErrors.filter((error) => !error.parentElement.classList.contains("user_phone")).forEach(error => error.remove());
        this.validateForm();
      }
    });
  }

  toggleErrors(data) {
    const errorKeys = Object.keys(data.errors);
    const validKeys = data.params.filter(param => !errorKeys.includes(param))
    errorKeys.forEach((key) => {
      if (key !== "phone") {
        const input = document.getElementById(`user_${key}`);
        if (input.nextElementSibling) {
          input.nextElementSibling.innerHTML = data.errors[key].pop();
        } else {
          input.insertAdjacentHTML('afterend', `<div class="input-error">${data.errors[key].pop()}</div>`)
        }
      }
    })
    validKeys.forEach((key) => {
      if (key !== "phone" && key !== "subscribed_mfe" && key !== "subscribed_newsletter") {
        const input = document.getElementById(`user_${key}`);
        if (input.nextElementSibling){
          input.nextElementSibling.remove();
        }
      }
    })
    this.validateForm();
  }


  submitForm() {
    event.preventDefault();
    const inputs = this.contactInputTargets;
    let hash = {}
    inputs.forEach((input) => {
      this.buildSentData(hash, input)
    });
    fetchWithToken(this.contactFormTarget.action, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: hash
      })
    })
    .then(response => response.json())
    .then((data) => {
      if (data.success) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'success',
          title: '👏 Profil mis à jour !',
          timer: 1500
        })
      }
    });
  }
}
