import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
// import MarkdownIt from 'markdown-it';

export default class extends Controller {
  static targets = ['container', 'form', 'input', 'front', 'back', 'concept']

  connect() {
  }

  cleanData() {
    event.preventDefault();
    this.inputTargets.forEach((input) => {
      if (input.innerText.startsWith("`")) {
        const newString = "<div><br/></div>".concat("", input.value);
        input.value = newString;
      }
    })
    this.formTarget.submit();
  }

  liveReload() {
    this.getCodeMarkdown(event.currentTarget);
  }

  getCodeMarkdown(input) {
    // const markdown = new MarkdownIt({xhtmlOut: true});
    // const value = input.value.replace(/(?<!`)(`{1}(?!`).*?`)+/g, (match) => {
    //   return markdown.renderInline(match)
    // });
    const value = input.value;
    const target = input.dataset.fetch
    this.targets.find(target).innerHTML = value;
    this.targets.find(target).querySelectorAll('a').forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  }

  showConcept() {
    const value = event.currentTarget.value
    this.conceptTarget.innerHTML = value;
    this.conceptTarget.setAttribute("class", `label ${value}`);

  }

  fetchFlashcard() {
    event.preventDefault();
    fetchWithToken(event.currentTarget.href, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    })
    .then(response => response.json())
    .then((data) => {
      this.containerTarget.innerHTML = data.html;
    });
  }

  newFlashcard() {
    event.preventDefault();
    fetchWithToken(event.currentTarget.href, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    })
    .then(response => response.json())
    .then((data) => {
      this.containerTarget.innerHTML = data.html;
    });
  }

  submitForm() {

  }
}
