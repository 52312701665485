import { Controller } from "stimulus";
import { markdown } from 'markdown';

export default class extends Controller {
  static targets = ["md"]

  connect() {
    this.mdTargets.forEach((md) => {
      md.innerHTML = markdown.toHTML(md.dataset.markdown);
    })
  }
}
