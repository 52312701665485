import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['addSessionBtn', 'preForm', 'modal'];

  connect() {
  }

  clickProp() {
    this.addSessionBtnTarget.click();
  }

  toggleAddSession() {
    const preForm = this.preFormTarget;
    preForm.classList.toggle('active');
    if (preForm.classList.contains('active')) {
      this.addSessionBtnTarget.innerHTML = `<i class="fas fa-minus"></i>`;
      this.addSessionBtnTarget.classList.add('active');
    } else {
      this.addSessionBtnTarget.innerHTML = `<i class="fas fa-plus"></i>`;
      this.addSessionBtnTarget.classList.remove('active');
    }
  }


  launchNewSessionForm() {
    this.modalController.open();
    fetch(event.currentTarget.value, { headers: { accept: "application/json" } })
      .then(response => response.json())
      .then((data) => {
        this.modalTarget.innerHTML = data.html;
      });
    event.currentTarget.value = "";
    this.toggleAddSession();
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "modal")
  }
}
