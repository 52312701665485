import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";

export default class extends Controller {
  static targets = ["sideForm", "session", "nextSession"];

  connect() {

  }

  toggleSideForm() {

    const canOpen = (element) => {
      if (this.hasNextSessionTarget) {
        return this.nextSessionTargets.find(nextSession => nextSession.contains(element))
      } else {
        return this.sessionTargets.includes(element) &&
        JSON.parse(element.dataset.canopen)
      }
    }

    const canClose = (element) => {
      return !canOpen(element) &&
      !this.sideFormTarget.contains(element)
    }

    const isOpen = () => {
      return !this.sideFormTarget.classList.contains('invisible');
    }

    const isSame = (element) => {
      return this.sideFormTarget.dataset.position === element.dataset.position;
    }

    const injectSession = (element) => {
      const sideForm = this.sideFormTarget;
      const session_id = parseInt(element.dataset.sessionId, 10);
      const position = parseInt(element.dataset.position, 10);
      const path = element.dataset.details;
      fetchWithToken(path, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: session_id,
          position: position
        })
      })
      .then(response => response.json())
      .then((data) => {
        const html = data.html;
        sideForm.innerHTML = html;
        sideForm.dataset.position = `${position}`;
      });
    }

    // ##### EVERYTHING STARTS HERE
    if (this.hasSideFormTarget) {
      const targetable = event.target.dataset.canopen === undefined ? event.target.parentElement : event.target;
	    if (canOpen(targetable)){
	      if (isOpen() && isSame(targetable)) {
	        event.currentTarget.classList.remove('form-toggled');
	        this.sideFormTarget.classList.add('invisible');
	      } else {
	        event.currentTarget.classList.remove('form-toggled');
	        this.sideFormTarget.classList.add('invisible');
	        if (this.hasNextSessionTarget) {
	          injectSession(this.nextSessionTargets.find(nextSession => nextSession.contains(targetable)));
	        } else {
	          injectSession(targetable);
	        }
	        event.currentTarget.classList.add('form-toggled');
	        this.sideFormTarget.classList.remove('invisible');
	      }
	    } else if (canClose(targetable) && isOpen()) {
	      event.currentTarget.classList.toggle('form-toggled');
	      this.sideFormTarget.classList.toggle('invisible');
	    }
    }
  }
}
