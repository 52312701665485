import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toolbar"];

  connect() {

  }

  toggleSideNav(event) {
    event.preventDefault();
    event.currentTarget.parentElement.classList.toggle("expanded");
    this.toolbarTargets.forEach((t) => {
      t.classList.toggle("invisible");
    });
    event.currentTarget.classList.toggle('toggled');
  }
}
