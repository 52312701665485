import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import 'select2';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ["date", "fromAt", "fromAtContainer", "toAt", "duration", "durationContainer", "locationContainer", "location", "remote", "student", "studentAttendees", "teacherAttendees", "teacher", "viewers", "viewer", "viewerContainer", "select", "session_asp", "progmod", "addableContentContainer"];

  connect() {
    this.dateTargets.forEach((input) => {
      this.initDatePickr(input);
    });
    this.initChoices();
    this.initLocation();
    setTimeout(() => { this.compileDates() }, 200);
  }

  // UPLOAD VIEWERS FORM PARTIAL
  updateViewerForm() {
    const path = this.teacherAttendeesTarget.dataset.path;
    const batchTeacherId = JSON.parse(this.teacherAttendeesTarget.value)[0];
    if (path.length != 0) {
      fetch(`${path}?batch_teacher_id=${batchTeacherId}`, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      })
      .then(response => response.json())
      .then((data) => {
        this.viewerContainerTarget.innerHTML = data.html;
      });
    }
  }

  compileDates() {
  	this.changeDate(this.fromAtTarget);
  }

  /// MANAGE SESSION DATE & DURATION
  setInitialDate(date, days) {
  	let newDate = date;
  	while (!days.includes(new Date(newDate).getDay())) {
  		let copy = new Date(newDate);
		  newDate = copy.setDate(new Date(newDate).getDate() + 1);
  	}
  	return newDate
  }

  async initDatePickr(input) {
    const realDateInput = input.parentElement.parentElement.querySelector('input:first-child');
    let date = Date.parse(realDateInput.value);
    const permittedDays = input.dataset.days ? JSON.parse(input.dataset.days) : undefined;
    if (permittedDays) {
    	date = await this.setInitialDate(date, permittedDays);
    	realDateInput.value = new Date(date);
    };
    const usualStartTime = input.dataset.startHour ? Date.parse(input.dataset.startHour) : undefined;
    const pickeredInput = flatpickr(input, {
      locale: "fr",
      altInput: true,
      enableTime: Boolean(input.id.match(/hour/g)) ? true : false,
      noCalendar: Boolean(input.id.match(/hour/g)) ? true : false,
      time_24hr: true,
      dateFormat: Boolean(input.id.match(/hour/g)) ? "H:i" : "l d F Y",
      altFormat: Boolean(input.id.match(/hour/g)) ? "H:i" : "l d F Y",
      minTime: "07:00",
      maxTime: "22:00",
      defaultDate: usualStartTime ? usualStartTime : date,
      enable: [
      	function(date) {
      		if (permittedDays) {
	      		return permittedDays.includes(date.getDay());
      		} else {
      			return date;
      		}
      	}
      ],
      onChange: () => {
        this.changeDate(realDateInput);
      }
    })
  }

  changeDate(input) {
    const getDate = (input) => {
      return new Date(Date.parse(input._flatpickr.selectedDates));
    }
    const dateInputs = Array.from(input.parentNode.parentNode.querySelectorAll('.inputs > input[data-session-form-target="date"]'));
    const dateElements = dateInputs.map(input => getDate(input));
    dateElements[0].setHours(dateElements[1].getHours());
    dateElements[0].setMinutes(dateElements[1].getMinutes());
    input.value = dateElements[0];
    if (/from_at/.test(input.id)) {
      this.computeToAtDate();
    };
    this.getExam();
  }

  computeToAtDate() {
    const setDuration = () => {
      if (this.durationTarget.value !== "0") {
        return parseInt(this.durationTarget.value, 10) * 60 * 1000;
      } else {
        return 90 * 60 * 1000;
      }
    }

    const toAtInput = this.toAtTarget;
    const fromAtDate = new Date(Date.parse(this.fromAtTarget.value)).getTime();
    const duration = setDuration();
    const newDate = new Date(fromAtDate + duration);
    toAtInput.value = newDate;
    const dateInputs = toAtInput.parentNode.parentNode.querySelectorAll('.inputs > input[data-session-form-target="date"]');
    dateInputs.forEach((dateInput) => {
      dateInput._flatpickr.setDate(Date.parse(newDate));
    });
  }

  toggleEndDate() {
    if (this.durationTarget.value === "0") {
      this.durationContainerTarget.style.display = "none";
      this.fromAtContainerTarget.querySelector("label").innerText = "Début";
      this.fromAtContainerTarget.querySelector(".inputs").style.paddingBottom = "0px";
      this.toAtTarget.parentElement.parentElement.classList.add('visible');
    }
  }



  /// ADDING RESOURCES FLASHCARDS / VOCABULARY LIST
  initChoices() {
    const buildOptions = (select, options) => {
      if (Boolean(select.dataset.uid)){
         return options;
      } else if (options.length !== undefined){
        return options.map((key) => {id: key.id})
      } else {
        return Object.keys(options).reverse().map((key) => buildGroup(key, options));
      };
    }


    const buildGroup = (key, data) => {
      return {
        text: key,
        children:
          data[key].map((list) => {
            return { id: list.id, text: list.title}
          })
      }
    }
    this.selectTargets.forEach((select) => {
      const options = JSON.parse(select.dataset.choices);
      const selectable = $(`#${select.id}`).select2({
        placeholder: select.dataset.prompt,
        data: buildOptions(select, options),
        width: '100%'
      });
      if (Boolean(select.dataset.selected)) {
        selectable.val(select.dataset.selected).trigger('change');

      };
      selectable.on('select2:open', (event) => {
        document.querySelector('.select2-search__field').setAttribute("placeholder", "Taper ici pour filtrer par titre");
      });
      selectable.on('change', (event) => {
        if (this.hasProgmodTarget && select.id == this.progmodTarget.id) {
          this.getExam();
        }
      });
    })
  }

  // ADDING LOCATIONS
  initLocation() {
    $(`#${this.locationTarget.id}`).select2({
      width: '100%',
      tags: true,
    })
    if (this.remoteTarget.checked) {
      document.querySelector(this.remoteTarget.dataset.toggle).classList.toggle('hidden');
    }
  }

  /// MANAGE SWITCHERS
  toggleInput() {
    const toggler = document.querySelector(event.currentTarget.dataset.toggle);
    toggler.classList.toggle('hidden');
    if (toggler.classList.contains('hidden')) {
      if (Object.keys(toggler.dataset).length > 0) {
        if (Boolean(toggler.dataset.editor)) {
          toggler.querySelector('trix-editor').value = "";
        }
      } else {
        $(`#${event.currentTarget.dataset.target}`).val(null).trigger('change');
      }
    }
  }


  /// MANAGE PARTICIPANTS
  toggleChecked() {
    const checkIfAllChecked = (type, checkboxes) => {
      if (checkboxes.every((box) => box.classList.contains('checked'))) {
        document.getElementById(type).checked = true;
      } else {
        document.getElementById(type).checked = false;
      };
    }

    const attendeeType = event.currentTarget.dataset.toggle;
    const checkBoxes = this.targets.findAll(attendeeType);
    if (attendeeType === "teacher") {
      checkBoxes.forEach(box => box.classList.remove('checked'));
      event.currentTarget.classList.toggle('checked');
    } else {
      event.currentTarget.classList.toggle('checked');
    }
    if (attendeeType === "student" || attendeeType === "session_asp") {checkIfAllChecked(attendeeType, checkBoxes)};
    this.computeAttendees(attendeeType, checkBoxes);
  }

  toggleCheckedForAll() {
    const attendeeType = event.currentTarget.dataset.target;
    const checkBoxes = this.targets.findAll(attendeeType)
    if (event.currentTarget.checked) {
      checkBoxes.forEach((box) => {
        if (!box.classList.contains('checked')) { box.classList.add('checked')}
      });
    } else {checkBoxes.forEach(box => box.classList.remove('checked'));}
    this.computeAttendees(attendeeType, checkBoxes);
  }

  computeAttendees(attendeeType, checkboxes) {
    const input = this.targets.find(`${attendeeType}Attendees`);
    if (input) {
      const checkedBoxes = checkboxes.filter(box => box.classList.contains('checked'));
      const resultArray = checkedBoxes.map(box => parseInt(box.dataset.value, 10));
      input.value = JSON.stringify(resultArray);
      if (attendeeType == "teacher"){this.updateViewerForm()};
    }
  }

  getExam() {
    let condition;
    if (this.hasProgmodTarget) {
      condition = this.progmodTarget.value != "" && this.fromAtTarget.value !=  "";
    } else {
      condition = this.fromAtTarget.value !=  "";
    }
    if (condition) {
      fetchWithToken(this.fromAtTarget.dataset.path, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          from_at: this.fromAtTarget.value,
          progmod: this.hasProgmodTarget ? this.progmodTarget.value : null
        })
      })
      .then(response => response.json())
      .then((data) => {
        if (this.hasAddableContentContainerTarget) {
          this.addableContentContainerTarget.innerHTML = data.html;
          this.initChoices();
        }
      })
    }
  }


  submitForm() {
    event.preventDefault();
    const form = event.currentTarget;
    const submitBtn = form.querySelector('input[type="submit"]');
    const asp = document.getElementById('session_asp');
    if (asp && asp.checked) {
      Swal.fire({
        title: 'Vous avez indiqué cette session étant ASP. Est-ce bien exact ?',
        text: "Après confirmation, le titre de la session, les objectifs et tous les contenus seront irrémédiablement effacés.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00E4A3',
        cancelButtonColor: '#504B73',
        confirmButtonText: '👍',
        cancelButtonText: '😱😅'
      }).then((result) => {
        if (result.value) {
          this.finalSubmit(form, submitBtn);
        } else {
          submitBtn.disabled = false;
        }
      })
    } else {
      // form.submit();
      this.finalSubmit(form, submitBtn);
    }
  }

  async finalSubmit(form, submitBtn) {
  	if (isNaN(Date.parse(this.fromAtTarget.value))) {
  		await Swal.fire({
  		  toast: true,
  		  position: 'top-end',
  		  showConfirmButton: false,
  		  icon: 'error',
  		  title: "👋 Vérifie la date, l'heure et/ou la durée!",
  		  timer: 2000
  		})
  		submitBtn.removeAttribute('disabled');
  	} else {
  		form.submit();
  	}
  }

  swapSessionType() {
    event.preventDefault();
    const path = event.currentTarget.href;
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir convertir cette session thématique en session ad-hoc ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00E4A3',
      cancelButtonColor: '#504B73',
      confirmButtonText: '👍',
      cancelButtonText: '😱😅'
    }).then((result) => {
      if (result.value) {
        fetch(`${path}`, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json())
        .then((data) => {
          this.element.innerHTML = data.html;
          this.dateTargets.forEach((input) => {
            this.initDatePickr(input);
          });
          this.initChoices();
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            icon: 'success',
            title: '✅ La session a bien été convertie en ad-hoc!',
            timer: 1500
          })
        });
      }
    })
  }
}
