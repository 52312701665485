import { Controller } from "stimulus";
import Swal from 'sweetalert2';

export default class extends Controller {

  connect() {
    Swal.fire({
      icon: 'error',
      title: `${this.element.dataset.message}`,
      text: `If you think it's a bug, please take a screenshot of this error message and send it to the admin, detailing the device and operating system you're using. 🙏`,
      showConfirmButton: true,
      timer: 5000
    })
  }

}
