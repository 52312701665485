import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import ProgressBar from "progressbar.js";

export default class extends Controller {
  static targets = ['vocContainer', 'voc', 'front', 'back', 'spell', 'progressbar', 'nsp', 'ok'];

  connect() {
    if (this.hasProgressbarTarget){this.scoreProgress();}
  }

  scoreProgress() {
    const progressbar = this.progressbarTarget;
    const score = Math.round(progressbar.dataset.score);
    const text = progressbar.querySelector('.progressbar-text');
    const bar = progressbar.querySelector('#progress');
    text.innerText = `${score}%`;
    text.style.left = `calc(${score}% - 8px)`;
    bar.style.strokeDashoffset = (100 - score);
  }

  update(){
    const word = this.vocTarget.dataset.word;
    fetchWithToken(this.element.dataset.path, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({word: JSON.parse(word)})
    })
      .then(response => response.json())
      .then((data) => {
        this.nextCard();
        this.okTarget.classList.toggle('active');
      });
  }

  dispatch() {
    if (event.keyCode == 39) {
      this.update();
      this.okTarget.classList.toggle('active');
    }
    if (event.keyCode == 37) {
      this.nextCard();
      this.nspTarget.classList.toggle('active');
    }

    if (event.keyCode == 32) {
      this.vocTarget.click();
    }
  }

  toggleCard() {
    const cardFront = this.vocTarget.querySelector('.voc-card-front');
    if (cardFront.classList.contains('invisible')) {
      this.vocTarget.classList.toggle('flipped');
      cardFront.classList.toggle('invisible');
      this.vocTarget.querySelector('.voc-card-back').classList.toggle('invisible');
    }
  }

  computeScore(data) {
    const score = parseInt(data.score, 10);
    this.progressbarTarget.dataset.score = score;
    this.scoreProgress();
    // this.progressbarTarget.querySelector('.progressbar-text').innerText = `${score}%`;
  }

  nextCard() {
    fetch(this.element.dataset.path, { headers: { accept: 'application/json' } })
      .then(response => response.json())
      .then((data) => {
        if (JSON.parse(data.next)) {
          const word = JSON.parse(data.next);
          this.frontTarget.innerText = word.term;
          this.backTarget.innerText = word.definition;
          this.computeScore(data);
          this.vocTarget.dataset.word = JSON.stringify(word);
          if (this.spellTarget){
            this.spellTarget.dataset.content = (data.sound) ? data.sound : word.term;
            if (data.sound) {this.spellTarget.click()};
          }
          this.nspTarget.classList.remove('active');
          this.toggleCard()
        } else {
          window.location.reload();
        }
      });
  }

  reset(){
    fetchWithToken(this.element.dataset.path, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (Boolean(data.success)) {
          window.location.reload();
        }
      });
  }
}
