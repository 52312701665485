import { Controller } from "stimulus";
import Reveal from "reveal.js";

export default class extends Controller {
  static targets = ["reveals", "goals", "exit"]

  connect() {
    Reveal.initialize({
      width: 1200,
      height: 800,
      margin: 0,
      border: 0,
      transition: 'linear',
      controlsTutorial: false,
      slideNumber: true,
      hash: true,
      history: true
    });
    Reveal.addKeyBinding( 13, function() {
      // if (Reveal.isOverview()) {
        Reveal.toggleOverview(false);
      // };
    });
    if (this.hasGoalsTarget && this.goalsTarget.dataset.progmod === "false") {
      // this.addLectureSupport();
      this.addLectureResources();
    }
  }

  addLectureSupport() {
    const attachments = document.querySelectorAll('action-text-attachment');
    let attached = []
    attachments.forEach((attachment) => {
      attached.push({
        filename: attachment.getAttribute(`filename`),
        url: attachment.getAttribute(`url`)
      })
    })

    const supports = attached.filter(attachment => Boolean(RegExp("support").test(attachment.filename)))
    if (supports.length != 0) {
      const html = `
        <section>
          <div class="slide-content">
            <h2>Support${supports.length > 1 ? "s" : ""} du cours</h2>
          </div>
        </section>
      `
      this.goalsTarget.insertAdjacentHTML('beforeend', html);
      supports.forEach((support) => this.buildLectureSupport(support));
    }
  }

  addLectureResources(){
    const links = document.querySelectorAll(".trix-content a");
    links.forEach(link => link.setAttribute("target", "_blank"));
    const resources = Array.from(document.querySelectorAll(".trix-content ul:nth-of-type(2) li > a"));
    const html = `
      <section class="resources-section">
        <div class="slide-content">
          <h2>À explorer !</h2>
          <ul id="resource-links"></ul>
        </div>
      </section>
    `
    this.exitTarget.insertAdjacentHTML("beforebegin", html);
    if (resources.length != 0) {
      const resourceList = document.getElementById("resource-links");
      resources.forEach((resource) => {
        const html = `<li><a href="${resource}" target="_blank">${resource.innerText}</a></li>`;
        resourceList.insertAdjacentHTML('beforeend', html);
      })
    }
    this.addNotSupportDocuments();
    if (document.getElementById('resource-links').innerHTML === "") {
      document.querySelector('.resources-section').remove();
    };
  }

  addNotSupportDocuments() {
    const resourceList = document.getElementById("resource-links");
    if (resourceList) {
      const attachments = document.querySelectorAll('action-text-attachment');
      let attached = []
      attachments.forEach((attachment) => {
        attached.push({
          filename: attachment.getAttribute(`filename`),
          url: attachment.getAttribute(`url`)
        })
      })
      const supports = attached.filter(attachment => !Boolean(RegExp("support").test(attachment.filename)))
      if (supports.length != 0) {
        supports.forEach((support) => {
          const html = `<li><a href="${support.url}" target="_blank">${support.filename}</a></li>`;
          resourceList.insertAdjacentHTML('beforeend', html);
        })
      }
    }
  }

  buildLectureSupport(support) {
    const html = `
      <section class="documents-section">
        <div class="slide-content">
          <div class="session-support">
            <a href="${support.url}" target="_blank" class="expand"><i class="fas fa-expand-alt"></i></a>
            <iframe
              height="100%"
              width= "100%"
              frameborder="0"
              src="${support.url}"
              allowfullscreen
              class="box-shadowed"
              data-reveal-target="iframe"></iframe>
          </div>
        </div>
      </section>
    `
    this.goalsTarget.insertAdjacentHTML('beforeend', html)
  }
}
