import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["displayable"];

  display() {
  	const elementToFetch = document.querySelector(this.displayableTarget.dataset.fetch);
  	if (elementToFetch) {
  		elementToFetch.classList.toggle('d-none');
  		this.displayableTarget.classList.toggle('d-none');
  	}
  }

}
