import Trix from "trix";

const initTrixWithInlineCode = () => {
  Trix.config.textAttributes.inlineCode = {
    tagName: "code",
    inheritable: true
  }
  Trix.config.textAttributes.underline = {
      style: { "textDecoration": "underline" },
      inheritable: true,
      parser: function (element) {
        var style = window.getComputedStyle(element);
        return style.textDecoration === "underline";
      }
    }


  addEventListener("trix-initialize", event => {
    const element = event.target
    const { toolbarElement, editor } = element

    const blockCodeButton = toolbarElement.querySelector("[data-trix-attribute=code]")
    const inlineCodeButton = blockCodeButton.cloneNode(true)


    inlineCodeButton.hidden = true
    inlineCodeButton.dataset.trixAttribute = "inlineCode"
    blockCodeButton.insertAdjacentElement("afterend", inlineCodeButton)

    element.addEventListener("trix-selection-change", _ => {
      const type = getCodeFormattingType()
      blockCodeButton.hidden = type == "inline"
      inlineCodeButton.hidden = type == "block"
    })

    function getCodeFormattingType() {
      if (editor.attributeIsActive("code")) return "block"
      if (editor.attributeIsActive("inlineCode")) return "inline"

      const range = editor.getSelectedRange()
      if (range[0] == range[1]) return "block"

      const text = editor.getSelectedDocument().toString().trim()
      return /\n/.test(text) ? "block" : "inline"
    }

    ///// UNDERLINE FEATURE
    const underlineButton = document.createElement("button");
    Object.assign(underlineButton, {
      type: "button",
      className: "trix-button trix-button--icon trix-button--icon-underline",
      title: "underline",
      tabIndex: "-1"
    })
    underlineButton.dataset.trixAttribute = "underline";
    underlineButton.innerText = "U";
    toolbarElement.querySelector('.trix-button-group--text-tools').appendChild(underlineButton);
  })
}

export { initTrixWithInlineCode };
