import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "input", "batchInput"];

  openNewTabAndSubmit() {
    event.preventDefault();
    // window.open("https://novexpat-alumni.slack.com/intl/en-gb/join/shared_invite/zt-5p7p2fgz-pf8o9DmAJE8kbpnqwY~gHg#/shared-invite/email/expanded-email-form", "_blank").focus();
    const inputValue = this.inputTarget.value;
    if (inputValue.length != 0) {
      const url = `${this.formTarget.action}?email=${this.inputTarget.value}&batch_id=${this.batchInputTarget.value}`
      fetch(url, {headers: {"Accept": "application/json"}})
        .then(response => response.json())
        .then((data) => {
          this.element.outerHTML = data.html
        })
    } else {
      this.inputTarget.classList.add("error");
      this.inputTarget.value = "";
      setTimeout(() => {
        const submitBtn = this.formTarget.querySelector("input[type=submit]");
        submitBtn.removeAttribute('disabled');
      }, 500)
    }
  }

}
