import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['fillIt', 'answers', 'sortable', 'reorder']

  connect() {
    if (this.hasFillItTarget) {
      this.fillItTargets.forEach((declaration) => {
        const inner = declaration.dataset.inner;
        const html = `<input type="text"></input>`
        declaration.innerHTML = inner.replace(/{{}}/g, html);
        // const variable = inner.match(/(?<={{)(?=}})/g);
      })
    }

    if (this.hasSortableTarget) {
      this.initSortable();
    }

    if (this.hasReorderTarget) {
      this.initReordable();
    }
  }

  initSortable() {
    Sortable.create(this.sortableTarget, {
      scroll: true,
      scrollSensitivity: 500,
      scrollSpeed: 30,
      direction: 'vertical'
    });
  }

  initReordable() {
    this.reorderTargets.forEach((li) => {
      const marks = li.querySelectorAll('mark');
      marks.forEach((mark) => {
        const parent = mark.parentNode;
        const wrapper = document.createElement('span');
        parent.replaceChild(wrapper, mark);
        wrapper.appendChild(mark);
        wrapper.dataset.action = "click->lecture-grammar#playword";
        wrapper.dataset.target = li.dataset.for;
        wrapper.classList.add('word-wrapper');
      })
    })
  }

  playword() {
    const wordWrapper = event.currentTarget;
    if (!wordWrapper.classList.contains('disabled')) {
      const reorderContainer = document.getElementById(`reorder${wordWrapper.dataset.target}`);
      if (reorderContainer) {
        const sentence = reorderContainer.querySelector('.sentence');
        sentence.insertAdjacentHTML('beforeend', wordWrapper.innerHTML);
        wordWrapper.classList.toggle('disabled');
        sentence.querySelector('mark:last-child').addEventListener('click', (event) => {
          wordWrapper.classList.toggle('disabled');
          event.currentTarget.remove();
        })
      }
    }
  }
}
