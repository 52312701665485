import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['topic']

  connect() {
    const topics = document.querySelectorAll('#topic');
    topics.forEach(topic => this.topicTarget.insertAdjacentHTML('beforeend', this.buildTopic(topic)))
  }

  buildTopic(topic) {
    return `<li>${topic.innerHTML}</li>`
  }
}
