import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";

export default class extends Controller {
  static targets = ["panel", "toggler"];

  connect() {
  }

  quitPanel() {
    if (this.hasPanelTarget) {
      if (event.keyCode) {
        if (event.keyCode == 27) {
          this.panelTarget.classList.remove('active');
          this.panelTarget.innerHTML = "";
        }
      } else {
        if (!this.panelTarget.contains(event.target) && this.togglerTargets.filter(toggler => toggler.contains(event.target)).length == 0) {
                this.panelTarget.classList.remove('active');
                this.panelTarget.innerHTML = "";
        }
      }
    }
  }

  togglePanel(){
    fetchWithToken(event.currentTarget.dataset.path, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    })
    .then(response => response.json())
    .then((data) => {
      this.panelTarget.innerHTML = data.html;
      if (!this.panelTarget.classList.contains('active')) {
        this.panelTarget.classList.add('active');
      }
    });
  }

}
