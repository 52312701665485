import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "modal", "modalContent", "player" ]

  open() {
    this.modalTarget.style.display = 'block';
    window.scrollTo(0, 0);
    if (this.hasPlayerTarget) {
      this.modalTarget.classList.add('video-modal');
    }
  }

  close() {
    if (this.hasModalTarget) {
      if (event.keyCode == 27) {
        this.modalTarget.style.display = 'none';
        this.modalContentTarget.innerHTML = "";
      }
    }
  }

  normalClose() {
    if (this.hasModalTarget) {
      if (event.keyCode == 27) {
        this.modalTarget.style.display = 'none';
      }
    }
  }

  closeModal() {
    if (event.currentTarget.classList.contains('modal-close')) {
      this.modalTarget.style.display = 'none';
      this.modalContentTarget.innerHTML = "";
    }
  }

  videoClose() {
    if (this.hasModalTarget && this.hasPlayerTarget) {
      if (event.keyCode === 27 || event.type === "click" || event.type === "touchstart") {
        this.modalTarget.style.display = 'none';
        this.modalTarget.classList.remove('video-modal');
        const event = document.createEvent("CustomEvent");
        event.initCustomEvent("dismiss-video", true, true, null);
        this.playerTarget.dispatchEvent(event);
      };
    }
  }

  launchNewSessionForm() {
    this.open();
    fetch(event.currentTarget.dataset.path, { headers: { accept: "application/json" } })
      .then(response => response.json())
      .then((data) => {
        this.modalContentTarget.innerHTML = data.html;
      });
  }

  launchEditSessionForm() {
    event.preventDefault();
    this.open();
    const btn = event.currentTarget;
    const path = (btn.dataset.path != undefined ) ? btn.dataset.path : btn.href
    fetch(path, { headers: { accept: "application/json" } })
      .then(response => response.json())
      .then((data) => {
        this.modalContentTarget.innerHTML = data.html;
      });
  }
}
