import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";

import intlTelInput from 'intl-tel-input';
import Typed from 'typed.js';
import "intl-tel-input/build/js/utils.js";

export default class extends Controller {

  static targets = ["stage", "contactForm", "contactInput", "contactNextStep", "newsletterForm", "newsletterInput", "waiting"];

  connect() {
    this.initiatePhone();
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 9) {e.preventDefault()}
    })
  }

  initiatePhone() {
    const phone = document.getElementById('user_phone');
    if (phone) {
      this.iti = intlTelInput(phone, {
        preferredCountries: ["fr", "us", "gb", "de", "es", "br"]
      });
      if (this.iti.isValidNumber()) {
        this.iti.setNumber(this.iti.getNumber());
      } else {
        phone.value = "";
        this.iti.setCountry("fr");
      }
    }
    this.validatePhone();
  }

  nextStage() {
    const currentStageStep = parseInt(event.currentTarget.dataset.target, 10) - 1;
    const currentStage = document.getElementById(`stage${currentStageStep}`);
    const nextStage = document.getElementById(`stage${event.currentTarget.dataset.target}`);
    this.stageTargets.forEach(target => target.style.transform = `translateX(-${(currentStageStep + 1) * 100}%)`);
    nextStage.style.transition = "all 350ms ease";
    if (this.stageTargets.indexOf(currentStage) + 1 === this.stageTargets.length - 1) {
      this.finalStep();
    }
  }

  previousStage() {
    const currentStageStep = parseInt(event.currentTarget.dataset.target, 10) + 1;
    const currentStage = document.getElementById(`stage${ currentStageStep}`);
    const nextStage = document.getElementById(`stage${event.currentTarget.dataset.target}`);
    this.stageTargets.forEach(target => target.style.transform = `translateX(-${(currentStageStep - 1) * 100}%)`);
    currentStage.style.transition = "none";
    nextStage.style.transition = "all 350ms ease";
  }


  fetchUpdateContact(hash) {
    fetchWithToken(this.contactFormTarget.action, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: hash
      })
    })
    .then(response => response.json())
    .then((data) => {
      if (!data.success){
        this.contactNextStepTarget.style.display = "none";
        const errorKeys = Object.keys(data.errors);
        const validKeys = data.params.filter(param => !errorKeys.includes(param))
        errorKeys.forEach((key) => {
          const input = document.getElementById(`user_${key}`);
          if (key === "phone") {
            input.parentElement.nextElementSibling.innerHTML = data.errors[key].pop();
          } else {
            input.nextElementSibling.innerHTML = data.errors[key].pop();
          }
        })
        validKeys.forEach((key) => {
          const input = document.getElementById(`user_${key}`);
          if (key === "phone") {
            input.parentElement.nextElementSibling.innerHTML = "";
          } else {
            input.nextElementSibling.innerHTML = "";
          }
        })
      } else {
        document.querySelectorAll('.input-error').forEach(input => input.innerHTML = "")
        this.contactNextStepTarget.style.display = "block";
      };
    });
  }

  buildSentData(hash, input) {
    const inputName = input.id.match(/user_(.*)/)[1];
    if (input.type == "checkbox"){
      hash[`${inputName}`] = input.checked;
    } else {
      hash[`${inputName}`] = input.value;
    }
  }

  validateContactForm() {
    const inputs = this.contactInputTargets;
    let hash = {}
    inputs.forEach((input) => {
      this.buildSentData(hash, input)
    });
    this.fetchUpdateContact(hash);
  }

  validatePhone() {
    if (this.iti.isValidNumber()){
      document.getElementById("user_phone").value = this.iti.getNumber();
      this.validateContactForm();
    } else {
      if (event) {
        event.currentTarget.parentElement.nextElementSibling.innerHTML = "Invalid mobile phone number!"
      }
      this.contactNextStepTarget.style.display = "none";
    }
  }


  validateNewsletterForm() {
    //GOING TO NEXT SCREEN
    const nextStage = document.getElementById(`stage${event.currentTarget.dataset.target}`);
    this.stageTargets.forEach(target => target.style.transform = `translateX(-${parseInt(event.currentTarget.dataset.target, 10) * 100}%)`);
    nextStage.style.transition = "all 350ms ease";

    const options = {
      strings: ['Finalizing the setup of your workspace...', 'Initializing your French program...'],
      typeSpeed: 30,
      showCursor: false,
      loop: true,
      loopCount: Infinity
    };

    const typed = new Typed(this.waitingTarget, options);

    // UPDATING NEWSLETTER SUBSCRIPTIONS
    const inputs = this.newsletterInputTargets;
    let hash = {}
    inputs.forEach((input) => {
      this.buildSentData(hash, input)
    });

    const path = this.element.dataset.path;
    fetchWithToken(this.newsletterFormTarget.action, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: hash
      })
    })
    .then(response => response.json())
    .then((data) => {
      const redirect = () => {window.location.href = path}
      setTimeout(redirect, 5000);
    });
  }


  finalStep() {
    const options = {
      strings: ['Finalizing the setup of your workspace...', 'Initializing your learning program...'],
      typeSpeed: 30,
      showCursor: false,
      loop: true,
      loopCount: Infinity
    };

    const typed = new Typed(this.waitingTarget, options);

    const redirect = () => { window.location.href = this.element.dataset.path }
    setTimeout(redirect, 5000);
  }
}
