import { Controller } from "stimulus"
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['']

  connect() {
  }

  play() {

    const msg = new SpeechSynthesisUtterance();
    const regex = /(.*)\((.*)\)(.*)/;
    const wordContainer = event.currentTarget;
    const text = wordContainer.dataset.content;
    msg.text = (text.match(regex) != undefined) ? text.match(regex).slice(1,text.match(regex).length).join('') : text;
    const allVoicesObtained = new Promise(function(resolve, reject) {
      let voices = window.speechSynthesis.getVoices();
      if (voices.length !== 0) {
        resolve(voices);
      } else {
        window.speechSynthesis.addEventListener("voiceschanged", function() {
          voices = window.speechSynthesis.getVoices();
          resolve(voices);
        });
      }
    });

    const toggleActive = (element) => {
      element.classList.toggle('active');
    }

    allVoicesObtained.then((voices) => {
      let frVoice = voices.filter(voice => voice.lang === "fr-CA")[0];
      if (frVoice === undefined) {
        frVoice = voices.filter(voice => voice.lang === "fr-FR").pop();
      }
      if (frVoice === undefined) {
        Swal({
          title: "Oops ! Looks like your operating system is not compatible with this feature",
          html: "The feature is compatible with nearly all browsers, except Internet Explorer and Opera. It should also work with IOs Safari, Android (except Opera) and Samsung Internet<br/> <a href='http://m.me/py.lg.9' target='_blank'>Give us a shout</a> if it still does not work using of these valid options. Thanks for your help.",
          type: "error",
          confirmButtonText: "OK, thanks 👌"
        })
      }
      else {
        msg.lang = frVoice.lang;
        msg.voice = frVoice;
        msg.pitch = 1.1;
        msg.rate = 1;
        toggleActive(wordContainer);
        speechSynthesis.speak(msg);
        msg.addEventListener('end', (e) => {
          toggleActive(wordContainer)
        });
      }
    });

  }
}
