import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import { annotate } from 'rough-notation';

export default class extends Controller {
  static targets = ["banner", "toQuestions", "container", "contentContainer", "question", "input", "next", "highlight"]

  connect() {
    this.highlightTargets.forEach(element => this.highlight(element));
  }

  toggleSelect() {
    switch (this.questionTarget.dataset.type) {
      case 'qcm' :
        this.toggleQcmQuestion();
        break;
    }
  }

  toggleQcmQuestion(){
    const inputs = this.inputTargets;
    console.log(inputs);
    const checkedInputs = inputs.filter(input => input.checked);
    console.log(checkedInputs);
    if (checkedInputs.length === 1) {
      event.currentTarget.checked = true;
      this.mark(event.currentTarget, 'box');
    } else {
      checkedInputs.splice(checkedInputs.indexOf(event.currentTarget), 1);
      if (checkedInputs.length === 0) {
        event.currentTarget.checked = false;
        this.draw(event.currentTarget.nextElementSibling, 'box', 'remove');
      } else {
        checkedInputs.forEach((input) => {
          input.checked = false;
          this.draw(input.nextElementSibling, 'box', 'remove');
        })
        event.currentTarget.checked = true;
        this.mark(event.currentTarget, 'box');
      }
    }
    this.toggleNext();
  }

  mark(element, type) {
    // console.log(element.checked);
    if (element.checked) {
      this.draw(element.nextElementSibling, type, '');
    } else {
      this.draw(element.nextElementSibling, type, 'remove');
    };
  }

  draw(element, type, action) {
   if (action === "remove") {
    element.nextElementSibling.remove();
   } else {
     const annotation = annotate(element, {
       type: type,
       color: '#E62C76',
       strokeWidth: 2
     });
     annotation.show();
   }
  }

  highlight(element) {
    const annotation = annotate(element, {
      type: 'highlight',
      color: '#FEF68E'
    });
    annotation.show();
  }
  toggleNext() {
    if (this.inputTargets.some((input) => input.checked)) {
      this.nextTarget.style.transform = "translateY(0%)";
    } else {
      this.nextTarget.style.transform = "translateY(100%)";
    }
  }

  nextQuestion() {
    const chosenOptions = this.inputTargets.filter(input => input.checked).flatMap(input => parseInt(input.value, 10));
    const questionId = this.questionTarget.id;
    fetchWithToken(event.currentTarget.dataset.path, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        question_id: questionId,
        chosen_options: chosenOptions
      })
    })
    .then(response => response.json())
    .then((data) => {
      if (data.finished) {
        this.containerTarget.innerHTML = data.html;
        this.highlightTargets.forEach(element => this.highlight(element));
      } else {
        this.contentContainerTarget.innerHTML = data.html;
      }
      this.element.scrollIntoView();
    });
  }
}
