import { Controller } from "stimulus";
// import "country-select-js";
import "select2";

export default class extends Controller {

  connect() {
  	const formatState = (state) => {
  		if (!state.id) {
  			return state.text;
  		}
			let $state = $(`<span><span class="flag-icon flag-icon-${state.id.toLowerCase()}"></span> ${state.text}</span>`);
			return $state;
  	}

  	const select = $("#user_nationality").select2({
			templateResult: formatState,
      width: '100%'
    });
  }
}
