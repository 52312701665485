import { Controller } from "stimulus";
import Plyr from 'plyr';

export default class extends Controller {
  static targets = ["player", "playlist", "disk", "album", "infos"]

  connect() {
    this.loadPlayList();
    const player = new Plyr("#radio-player", {
      speed: {selected: 1, options: ["1"]}
    });
    const songs = Array.from(this.playlistTarget.querySelectorAll('.playlist-list'));
    player.on('play', () => {
      setTimeout(() => {
        this.diskTarget.classList.add('rotate');
        this.albumTarget.classList.add('rotate');
      }, 500)
    })
    player.on('pause', () => {
      this.diskTarget.classList.remove('rotate');
      this.albumTarget.classList.remove('rotate');
    })
    player.on('ended', () => {
      const playingSongIndex = songs.indexOf(this.playlistTarget.querySelector('.playlist-list.playing'));
      if (playingSongIndex < songs.length - 1 ) {
        songs[playingSongIndex + 1].click();
      }
    })
  }

  loadPlayList() {
    JSON.parse(this.playlistTarget.dataset.playlist).forEach((song, index) => {
      const html = `
        <div class="playlist-list" data-video="${song.videoId}" data-title="${song.title}" data-artist="${song.artist}" data-portal="${song.portal}" data-action="click->playlist#playSong">
          <div class="number">${index + 1}</div>
          <div class="infos">
            <h5>${song.title}</h5>
            <h6>${song.artist}</h6>
          </div>
          <img src="http://res.cloudinary.com/pywagon/image/upload/v1/papl/music/${song.portal}.jpg">
        </div>`
      this.playlistTarget.insertAdjacentHTML('beforeend', html);
      if (index === 0) {
        this.playlistTarget.querySelector('.playlist-list').classList.add('playing');
        this.albumTarget.src = `http://res.cloudinary.com/pywagon/image/upload/v1/papl/music/${song.portal}.jpg`
      }
    });
    const playlistName = this.playlistTarget.dataset.playlistName
    const playlistDuration = this.playlistTarget.dataset.playlistDuration
    const playlistLength = this.playlistTarget.dataset.playlistLength
    const infosHtml = `
      <h3>${playlistName}</h3>
      <h4><span id="playlist-songs-length">${playlistLength}</span> Titres | <span id="playlist-duration">${playlistDuration}</span></h4>`
    this.infosTarget.innerHTML = infosHtml;
  }

  playSong() {
    this.diskTarget.classList.remove('rotate');
    this.albumTarget.classList.remove('rotate');
    const portal = event.currentTarget.dataset.portal;
    const videoId = event.currentTarget.dataset.video;
    const iframe = `
            <iframe
              src="https://www.youtube.com/embed/${videoId}?modestbranding&iv_load_policy=3&cc_load_policy=3&showinfo=0&enablejsapi=1&origin=${window.location.origin}"
              allowfullscreen
              allowtransparency
              allow="autoplay"
            ></iframe>`
    this.playerTarget.innerHTML = iframe;
    const player = new Plyr(this.playerTarget, {
      autoplay: true,
      speed: {selected: 1, options: ["1"]}
    })
    this.playlistTarget.querySelectorAll('.playlist-list').forEach((list) => {
      list.classList.remove('playing');
    })
    event.currentTarget.classList.toggle('playing');
    this.albumTarget.src = `http://res.cloudinary.com/pywagon/image/upload/v1/papl/music/${portal}.jpg`;

  }
}
