import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['card', 'actions', 'cardBack', 'spinner', 'concept']

  connect() {
  }

  flipCard() {
    event.currentTarget.classList.toggle('flipped');
    this.cardTargets.forEach((card) => {
      card.classList.toggle('invisible');
    });
    this.actionsTarget.classList.toggle('invisible');
  }

  submitMasteredCard() {
    this.cardBackTarget.parentElement.classList.toggle("cardSlideIn");
    this.cardBackTarget.parentElement.classList.add("cardSlideRight");
    this.spinnerTarget.innerHTML = `<i class="fas fa-circle-notch"></i>`;
  }

  submitNotMasteredCard() {
    this.cardBackTarget.parentElement.classList.toggle("cardSlideIn");
    this.cardBackTarget.parentElement.classList.add("cardSlideLeft");
    this.spinnerTarget.innerHTML = `<i class="fas fa-circle-notch"></i>`;
  }
}
