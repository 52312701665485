import { Controller } from "stimulus"
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['']

  connect() {
  }

  play(e) {
    const wordContainer = e.currentTarget;
    const soundURL = wordContainer.dataset.content;
    const audio = new Audio(soundURL);
    audio.load(); // Necessary to work well on IOS Safari
    audio.addEventListener("canplaythrough", (event) => {
      wordContainer.classList.toggle('active');
      audio.play();
    });
    audio.addEventListener('ended', (e) => {
      wordContainer.classList.toggle('active');
    });
  }
}
