import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "inputable", "hiddenField", "submit", "answer"];

  connect() {
    if (this.hasInputTarget) {
      this.inputTargets.forEach((input) => {
        const questionId = input.dataset.question;
        const questionType = input.dataset.questionType;
        const html = `<input className="form-control string" type="text" name="session_challenge[grammar_challenge][answers][${questionId}]" id="session_challenge_grammar_challenge_answers_${questionId}" data-action="keyup-> grammar-challenge#fillFinalHiddenInput"
        data-grammar-challenge-target="inputable" data-question-type="${questionType}" placeholder=${this._setPlaceHolder(input)}>`
        if (questionType === "place_and_fill") {
          input.innerHTML = input.innerHTML.replace(/{{}}/g, html);
        } else {
          input.innerHTML = this._streamLineInputHTML(input).replace(/{{}}/g, html);
        }
        this.makeIdUnique(questionId);
      });
      // Add extra hidden input
      this.inputTargets.forEach((input) => {
        const questionId = input.dataset.question;
        const questionType = input.dataset.questionType;
        const html = `<input className="form-control hidden" type="hidden" name="session_challenge[grammar_challenge][answers][${questionId}]" id="session_challenge_grammar_challenge_answers_${questionId}" data-grammar-challenge-target="hiddenField" data-question-type="${questionType}">`
        input.insertAdjacentHTML("beforeend", html);
      })
    }
  }

  makeIdUnique(questionId) {
    const inputs = document.querySelectorAll(`#session_challenge_grammar_challenge_answers_${questionId}`);
    if (inputs.length > 1) {
      inputs.forEach((input, index) => {
        input.setAttribute("name", `session_challenge[grammar_challenge][answers][${questionId}]_${index}]`);
        input.setAttribute("id", `#session_challenge_grammar_challenge_answers_${questionId}_${index}`);
      })
    }
  }

  fillFinalHiddenInput(event) {
    const input = this.inputTargets.find(input => input.contains(event.currentTarget));
    // Create array of inputable values
    const inputablesOfInput = this.inputableTargets.filter(inputable => input.contains(inputable));
    const inputableValues = inputablesOfInput.map(input => input.value.length === 0 ? input.value : `**${input.value}**`);
    // Get hiddenField
    const hiddenField = this.hiddenFieldTargets.find(hiddenField => input.contains(hiddenField));

    if (inputableValues.filter((str) => str !== '').length === 0) {
      hiddenField.value = ''
    } else {
      // Get QuestionText
      const questionText = input.dataset.questionText;
      // Replace every {{}} by an element of the array above
      const userAnswer = this._replaceCurlyBracesInText(questionText, /\{\{\}\}/g, inputableValues).replace(/\s+/g, ' ').trim();
      hiddenField.value = userAnswer;
    }
    this.formIsSubmitable()
  }

  formIsSubmitable() {
    if (this.hiddenFieldTargets.length != 0) {
      let notAnswered = this.hiddenFieldTargets.filter(input => input.value == '');
      if (notAnswered.length == 0) {
        this.submitTarget.disabled = false;
      } else {
        this.submitTarget.disabled = true;
      }
    }
  }

  _replaceCurlyBracesInText(sourceText, regex, values) {
    let list = [];
    const getValue = () => {
      if (list.length <= 0) { list = Array.from(values)}
      return list.shift();
    }
    return sourceText.replace(regex, getValue);
  }

  _setPlaceHolder(input) {
    if (input.dataset.questionType == "place_and_fill") {
      return ""
    }
    const verb = input.innerText.match(/\(.*\)/g);
    return (verb) ? (verb) : ""
  }

  _streamLineInputHTML(input) {
    const verb = input.innerText.match(/\(.*\)/g);
    return (verb) ? input.innerHTML.replace(/\(.*\)/g, "") : input.innerHTML;
  }
  _streamLineString(string) {
    return (string.match(/\(.*\)/g)) ? string.replace(/\(.*\)/g, "") : string;
  }
}
