import { Controller } from "stimulus";
import Plyr from 'plyr';

export default class extends Controller {
  static targets = ["player", "ccBtn", "captionContainer", "caption"];

  connect() {
    const player = new Plyr(`#${this.playerTarget.id}`, {
      speed: {
        selected: 1,
        options: [.5, .75, 1]
      }
    });
    global.player = player;
    if (this.hasCcBtnTarget) {
      this.toggleCaptions();
    }
  }

  stopPlayer() {
    player.stop();
  }

  toggleCaptions() {
    const captions = this.captionTargets;
    this.ccBtnTarget.classList.toggle('clicked');
    if (this.ccBtnTarget.classList.contains('clicked')) {
      this.captionContainerTarget.classList.toggle('hidden');
      player.on('timeupdate', (e) => {
        const instance = e.detail.plyr;
        captions.forEach((caption) => {
          const captionStart = caption.dataset.start;
          const captionEnd = caption.dataset.end;
          const currentTime = instance.currentTime;
          if (currentTime >= captionStart && currentTime <= captionEnd) {
            caption.style.display = "block";
          } else {
            caption.style.display = "none";
          }

        });
      })
    } else {
      this.captionContainerTarget.classList.toggle('hidden');
    }
  }
}
