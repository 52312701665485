import { Controller } from "stimulus";
import ProgressBar from "progressbar.js";

export default class extends Controller {
  static targets = ["circle"]

  connect() {
    const progressCircle = this.circleTarget;
    const colorFirst = progressCircle.dataset.colorFirst;
    const colorLast = progressCircle.dataset.colorLast;
    let progress = 0;
    if (Boolean(progressCircle.dataset.score)) {
      progress = Math.round(progressCircle.dataset.score);
    } else {
      const hours = parseFloat(progressCircle.dataset.hours);
      const classPackage = parseFloat(progressCircle.dataset.package);
      progress = Math.round(hours/classPackage * 100);
    }

    let linearGradientCircle = `
    <defs>
      <linearGradient id="gradientCircle" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
        <stop offset="0%" stop-color="${colorFirst}"/>
        <stop offset="100%" stop-color="${colorLast}"/>
      </linearGradient>
    </defs>`;
    const bar = new ProgressBar.Circle(progressCircle, {
      strokeWidth: 6,
      color: 'url(#gradientCircle)',
      trailColor: '#E5E5E5',
      trailWidth: 3,
      easing: 'easeInOut',
      duration: 3000,
      svgStyle: null,
      text: {
        value: '',
        alignToBottom: false
      },
      step: (state, bar) => {
        const value = progress;
        const text = `
          <div>${value}<span> %</span></div>
        `;
        bar.setText(text);
        bar.text.style.color = colorFirst;
      }
    });
    bar.svg.insertAdjacentHTML('afterBegin', linearGradientCircle);
    // bar.text.style.fontFamily = '"museo-sans-rounded", sans-serif';
    bar.animate(Number.parseInt(progress,10)/100);  // Number from 0.0 to 1.0
  }

  disconnect() {
    this.circleTarget.innerHTML = "";
  }
}
