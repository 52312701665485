import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import Croppr from 'croppr';

export default class extends Controller {
  static targets = ["dismiss", "cropWaitFinal", "cropWait", "croppingBox", "pictureForm", "croppingStage", "croppingArea", "inputPictureLoader", "inputPictureSave", "filePreview", "fileCrop", "pictureNextStep"];

  connect() {
    this.controllers = this.element.dataset.controller.split(' ');
    if (this.hasDismissTarget) {
      this.dismissTarget.dataset.img = this.filePreviewTarget.style.backgroundImage;
    }
  }

  chooseFile() {
    this.inputPictureLoaderTarget.click();
  }

  dismiss() {
    this.croppingStageTarget.classList.toggle('active');
    this.croppingBoxTarget.remove();
    this.croppingAreaTarget.classList.toggle('active');
    this.cropWaitTarget.classList.toggle('active');
    this.filePreviewTarget.style.backgroundImage = this.dismissTarget.dataset.img;
  }

  loadFile() {
    this.croppingStageTarget.classList.toggle('active');
    const preview = this.filePreviewTarget;
    const file = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
        preview.style.backgroundImage = `url(${reader.result})`;
        this.cropWaitTarget.classList.toggle('active');
        this.croppingAreaTarget.classList.toggle('active');
        this.croppingAreaTarget.insertAdjacentHTML('beforeend',
          `
            <div class="cropping-box" data-cropper-target="croppingBox">
              <img src="${reader.result}" data-cropper-target="fileCrop">
              <div class="next-stage">
                <div class="cta cta-parma" data-cropper-target="inputPictureSave">Save this beautiful picture of me</div>
              </div>
            </div>
          `
        )
        this.croppr();
        this.inputPictureSaveTarget.addEventListener('click', (e) => {
          this.croppingBoxTarget.remove();
          this.croppingAreaTarget.classList.toggle('active');
          this.cropWaitFinalTarget.classList.toggle('active');
          this.savePicture(file);
        })
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  croppr() {
    const cropContainer = this.fileCropTarget;
    const preview = this.filePreviewTarget;
    const croppr = new Croppr(cropContainer, {
      aspectRatio: 1,
      minSize: [150, 150, 'px'],
      onInitialize: function(instance) {
        const value = instance.getValue();
        preview.dataset.crop = `{"x": "${value.x}", "y": "${value.y}", "width": "${value.width}", "height": "${value.height}"}`;
      },
      onCropEnd: function(value) {
        preview.dataset.crop = `{"x": "${value.x}", "y": "${value.y}", "width": "${value.width}", "height": "${value.height}"}`;
      }
    });
  }


  savePicture(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('crop', this.filePreviewTarget.dataset.crop);
    fetchWithToken(this.filePreviewTarget.dataset.path, {
      method: "POST",
      headers: {
        "Accept": "application/json",
      },
      body: formData
    })
      .then(response => response.json())
      .then((data) => {
        this.croppingStageTarget.classList.toggle('active');
        this.cropWaitTarget.classList.toggle('active');
        this.cropWaitFinalTarget.classList.toggle('active');
        this.filePreviewTarget.style.backgroundImage = `url(${data.url})`;
        this.dismissTarget.dataset.img = `url(${data.url})`;
        const navbarAvatar = document.getElementById('navbar-avatar');
        if (navbarAvatar){navbarAvatar.src = data.url}
        if (this.controllers.includes("onboarding")) {this.insertNextStage()};
      });
  }


  insertNextStage() {
    this.pictureNextStepTarget.innerHTML = `
      <div class="next-stage">
        <div class="cta cta-parma" data-action="click->onboarding#nextStage" data-target="2">NEXT STEP</div>
      </div>
    `
  }
}
