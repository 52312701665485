import { Controller } from "stimulus";
import { fetchWithToken } from "../packs/utils/fetch_with_token";
import Sortable from 'sortablejs';
import Typed from 'typed.js';


export default class extends Controller {
  static targets = ["importVocLauncher", "importVoc", "importTextarea", "addNewBtn", "form", "vocListing", "waiting"];

  connect() {
    this.initSortable();
  }

  /// INIT SORTABLE
  initSortable() {
    Sortable.create(this.vocListingTarget, {
      ghostClass: 'ghost',
      scroll: true,
      scrollSensitivity: 500,
      scrollSpeed: 30,
      direction: 'vertical',
      filter: 'input',
      preventOnFilter: false,
      onEnd: (event) => {
        const newPosition = event.newIndex;
        const vocRank = event.item.dataset.vocPos;
        const listId = this.formTarget.dataset.listId;
        fetchWithToken('/vocs/move', {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            list_id: listId,
            rank: vocRank,
            new_rank: newPosition
          })
        })
        .then(response => response.json())
        .then((data) => {
          this.vocListingTarget.innerHTML = data.html;
        });
      }
    });
  }

  /// FOR BULK VOC IMPORT
  enableTabImport() {
    if (event.keyCode == 9) {
      event.preventDefault();
      const cursorPosition = event.currentTarget.selectionStart;
      const value = event.currentTarget.value;
      event.currentTarget.value = `${value.substring(0, cursorPosition)}\t${value.substring(cursorPosition)}`
      event.currentTarget.focus();
    }
  }

  toggleImport() {
    const launcher = this.importVocLauncherTarget;

    const toggleClassName = (element, className) => {
      element.classList.toggle(className);
    }

    const computeLinkText = () => {
      if (this.importVocTarget.classList.contains('hidden')){
        return `+ Import rapide de mots de vocabulaire`;
      } else {
        return `Annuler l'import`;
      }
    }

    this.importTextareaTarget.value = "";
    this.importTextareaTarget.setAttribute('placeholder','Mot 1\tDéfinition1\r\nMot 2\tDéfinition2\r\nMot 3\tDéfinition3');
    toggleClassName(launcher, 'open');
    toggleClassName(this.importVocTarget, 'hidden');
    toggleClassName(this.importVocTarget, 'pullDown')
    launcher.innerText = computeLinkText();
    toggleClassName(this.addNewBtnTarget, 'hidden');
    toggleClassName(this.vocListingTarget, 'hidden');
  }

  importVoc() {
    const cleanData = (element) => {
      let outputArray = [];
      const reg = RegExp("(?<tab>(\t){1,})");
      element.split(reg).forEach((item) => {
        if (!reg.test(item)) {outputArray.push(item.trim())}
      });
      return outputArray.filter(element => element !== "");
    }

    const buildWords = (array) => {
      return {term: array[0], definition: array[1]}
    }
    const vocsToImport = this.importTextareaTarget.value.trim().split(/\n/).map(word => cleanData(word));


    fetchWithToken('/vocs/bundle_create', {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        list_id: this.formTarget.dataset.listId,
        words: vocsToImport.map(word => buildWords(word))
      })
    })
    .then(response => response.json())
    .then((data) => {
      this.vocListingTarget.innerHTML = data.html;
      this.toggleImport();
    });
  }


  submitForm() {
    event.preventDefault();
    this.waitingTarget.classList.toggle('hidden');
    const options = {
      strings: ['Ajout de la liste dans la base de données', 'Génération du PDF', 'Merci de patienter...'],
      typeSpeed: 100,
      loop: true,
      loopCount: Infinity
    };

    const typed = new Typed(this.waitingTarget.querySelector('.typed'), options);
    this.formTarget.submit();
  }
}
